import type { Params } from '@remix-run/react'

const paths = {
  PARTNERS_LANDING_PAGE: '/$landingPath',
  TERMS_OF_USE: '/termos-de-uso',
  TERMS_OF_SERVICE: '/termos-de-servico',
  LEADS: '/cadastro',
  ACQUISITION_FLOW_PARTNER_LOADING: '/parceiros/$partnerId/$leadId',
  ACQUISITION_FLOW_PARTNER_CONFIRM_REGISTER:
    '/parceiros/$partnerId/$leadId/confirmar',
  ACQUISITION_FLOW_PARTNER_RANGE_BILL_ACCOUNT:
    '/parceiros/$partnerId/$leadId/media-valor-conta',
  ACQUISITION_FLOW_HOME: '/quero-economizar',
  ACQUISITION_FLOW_REGISTER_EMAIL:
    '/quero-economizar/cadastro/conta/$leadId/email',
  ACQUISITION_FLOW_REGISTER_BUSINESS_CATEGORY:
    '/quero-economizar/cadastro/conta/$leadId/lugar/categoria',
  ACQUISITION_FLOW_REGISTER_PERSON_DATA:
    '/quero-economizar/cadastro/seus-dados',
  ACQUISITION_FLOW_REGISTER_BUSINESS:
    '/quero-economizar/cadastro/conta/$leadId/seu-negocio',
  ACQUISITION_FLOW_REGISTER_BUSINESS_RELATION:
    '/quero-economizar/cadastro/conta/$leadId/lugar/$category/relacionamento',
  ACQUISITION_FLOW_REGISTER_BUSINESS_MOTIVATION:
    '/quero-economizar/cadastro/conta/$leadId/lugar/$category/motivacao',
  ACQUISITION_FLOW_REGISTER_BUSINESS_DETAILS:
    '/quero-economizar/cadastro/conta/$leadId/lugar/$category/detalhes',
  ACQUISITION_FLOW_REGISTER_CPF: '/quero-economizar/cadastro/conta/$leadId/cpf',
  ACQUISITION_FLOW_REGISTERED:
    '/quero-economizar/cadastro/conta/$leadId/cadastrado',
  ACQUISITION_FLOW_SAVINGS_SIMULATION: '/quero-economizar/cadastro/simulacao',
  ACQUISITION_FLOW_BUSINESS_ADDRESS:
    '/quero-economizar/cadastro/conta/$leadId/endereco-do-negocio',
  ACQUISITION_FLOW_BUSINESS_ADDRESS_EDIT:
    '/quero-economizar/cadastro/conta/$leadId/endereco-do-negocio/editar',
  ACQUISITION_FLOW_CONFIRM_CUSTOMER_UNIT:
    '/quero-economizar/cadastro/conta/$leadId/confirmar-uc',
  ACQUISITION_FLOW_SELECT_CUSTOMER_UNIT:
    '/quero-economizar/cadastro/conta/$leadId/selecionar-uc',
  ACQUISITION_FLOW_BILL_OWNER:
    '/quero-economizar/cadastro/conta/$leadId/titularidade',
  ACQUISITION_FLOW_BILL_DOCUMENT:
    '/quero-economizar/cadastro/conta/$leadId/cnpj',
  ACQUISITION_FLOW_COMPANY_FULL_NAME:
    '/quero-economizar/cadastro/conta/$leadId/razao-social',
  ACQUISITION_FLOW_BILL_INSTALLATION_NUMBER:
    '/quero-economizar/cadastro/conta/$leadId/dados-da-conta/numero-de-instalacao',
  ACQUISITION_FLOW_BILL_CLIENT_NUMBER:
    '/quero-economizar/cadastro/conta/$leadId/dados-da-conta/numero-de-cliente',
  ACQUISITION_FLOW_BILL_UPLOAD:
    '/quero-economizar/cadastro/conta/$leadId/upload-conta',
  ACQUISITION_FLOW_SIGNATURE_INDEX: '/quero-economizar/cadastro/assinatura',
  ACQUISITION_FLOW_SIGNATURE_ID: '/quero-economizar/cadastro/assinatura/$id',
  ACQUISITION_FLOW_SIGNATURE_NEW:
    '/quero-economizar/cadastro/assinatura/$id/nova',
  ACQUISITION_FLOW_SIGNATURE_EMBED:
    '/quero-economizar/cadastro/assinatura/$id/assinar',
  ACQUISITION_FLOW_DOCUMENT_INTRO:
    '/quero-economizar/cadastro/conta/$leadId/documento',
  ACQUISITION_FLOW_DOCUMENT_CHOICE:
    '/quero-economizar/cadastro/conta/$leadId/documento/selecionar',
  ACQUISITION_FLOW_DOCUMENT_TYPE:
    '/quero-economizar/cadastro/conta/$leadId/documento/$docType',
  ACQUISITION_FLOW_WAIT_LIST: '/quero-economizar/lista-espera',
  ACQUISITION_FLOW_WAIT_LIST_FINISH:
    '/quero-economizar/lista-espera/cadastrado',
  ACQUISITION_FLOW_SUCCESS: '/quero-economizar/cadastro/conta/$leadId/sucesso',
} as const

export const apiPaths = {
  GENERATE_SIGNED_S3_URL_BILL: '/api/generate-signed-s3-url-bill',
  GENERATE_SIGNED_S3_URL_DOCUMENT: '/api/generate-signed-s3-url-document',
  SEARCH_CEP: '/api/cep',
  POSTHOG_SIGNED: '/api/posthog-signed',
  REPORT: '/api/report',
  PROCESS_DOCUMENT: '/api/process-document',
  CHECK_SIGNATURE: '/api/signature-ready',
} as const

type LeadsPathsKeys = keyof typeof paths
export type LeadsPaths = (typeof paths)[LeadsPathsKeys]

type Replacements = { [key: string]: string }

const appendSearchParamsString = (url: string, searchParams?: string) => {
  const pathWithParams = searchParams ? `${url}?${searchParams}` : url

  return pathWithParams
}

// Replaces url dynamic paths with values, for example: /user/$id -> /user/123
export const pathReplace = (
  path: LeadsPathsKeys,
  replacements: Replacements | Params,
  searchParams?: URLSearchParams | string,
) => {
  let replacedUrl: string = paths[path]

  if (replacements) {
    Object.keys(replacements).forEach(key => {
      const replacementValue = replacements[key]

      if (replacementValue) {
        replacedUrl = replacedUrl.replace(
          `$${key}`,
          replacementValue.toString(),
        )
      } else {
        replacedUrl = replacedUrl.replace(`$${key}`, '')
      }
    })
  }

  if (searchParams) {
    searchParams =
      typeof searchParams === 'string'
        ? new URLSearchParams(searchParams)
        : searchParams
    replacedUrl = appendSearchParamsString(replacedUrl, searchParams.toString())
  }

  return replacedUrl
}

/**
 * Returns the pathname without the params for the given url. Example:
 *
 *  - url: `/user/123/likes/abc`
 *
 *  - clean path: `/user/$id/likes/$commentId`
 *
 * @param params the loader/action arg params
 * @param url the request url
 * @returns the path without params
 */
export const urlToCleanPath = (params: Params, url: URL | string) => {
  let cleanPath = typeof url === 'string' ? url : url.pathname

  for (const [key, value] of Object.entries(params)) {
    cleanPath = cleanPath.replace(`/${value}`, `/$${key}`)
  }

  cleanPath = cleanPath.replace(/\/$/, '') // Remove trailling slash

  return cleanPath
}

export default paths
